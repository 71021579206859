import Select from '@paljs/ui/Select';
import { Radio } from '@paljs/ui/Radio';
import { Card, CardBody } from '@paljs/ui/Card';
import { Checkbox } from '@paljs/ui/Checkbox';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/select2.css';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  dateFormat,
  numFormatter,
  checkNumber,
  randomKey,
  authCheck,
  doSelect2,
  appendScript,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;

const InputPage = () => {
  const [machineData, setMachineData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    appendScript();
    authCheck(state.authData);
    getApi();
    getGroups();
  }, []);

  const [machineCode, setMachineCode] = useState('');
  const [description, setDescription] = useState('');
  const [operations, setOperations] = useState([]);
  const [group, setGroup] = useState('');
  const [purchasedDate, setPurchasedDate] = useState(dateFormat(new Date()));
  const [steam, setSteam] = useState(0);
  const [power, setPower] = useState(0);
  const [thermic, setThermic] = useState(0);
  const [tankCapacity, setTankCapacity] = useState(0);
  const [pickId, setPickId] = useState('');
  const [defaultGroup, setdefaultGroup] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [defaultProcess, setdefaultProcess] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [groupOps, setGroupOps] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [piInc, setPiInc] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'tankCapacity':
        setTankCapacity(checkNumber(event.target, '3.2'));
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'machineCode':
        setMachineCode(event.target.value);
        break;
      case 'thermic':
        setThermic(checkNumber(event.target, '7'));
        break;
      case 'purchasedDate':
        setPurchasedDate(event.target.value);
        break;
      case 'steam':
        setSteam(checkNumber(event.target, '4'));
        break;
      case 'power':
        setPower(checkNumber(event.target, '3.2'));
        break;
    }
  };

  const groupCodeChange = (event) => {
    showLoader();
    setGroup(event.value);
    setdefaultGroup({ label: event.label, value: event.value });
    computeProcessOptions(event.value);
    setOperations([]);
    setdefaultProcess([]);
    pullDownReset('group');
    hideLoader();
  };

  const processCodeChange = (event) => {
    showLoader();
    let selected = [];
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setOperations(selected);
    setdefaultProcess(event);
    pullDownReset('operations');
    hideLoader();
  };

  const computeProcessOptions = (groupId) => {
    let options = [];
    if (groupId) {
      let obj = allGroups.find((o) => o.id == groupId);
      obj.attributes.operations.data.forEach(function (item, index) {
        options.push({ label: item.attributes.operationCode + ' - ' + item.attributes.description, value: item.id });
      });
      setProcessOptions(options);
    }
    return options;
  };

  const clearFormData = () => {
    setSave(pageAccess.write===1);
    setDescription('');
    setMachineCode('');
    setPurchasedDate(dateFormat(new Date()));
    setGroup('');
    setPickId('');
    setdefaultGroup([]);
    setdefaultProcess([]);
    setProcessOptions([]);
    setSteam(0);
    setPower(0);
    setThermic(0);
    setTankCapacity(0);
    resetRequired();
    document.querySelectorAll('.pi-input-container').forEach(function (el, i) {
      el.remove();
    });
    checkpiO();
  };

  const getGroups = () => {
    showLoader();
    axios
      .get(baseUri() + 'groups?populate=operations', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setAllGroups(response.data.data);
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.groupCode + ' - ' + item.attributes.description, value: item.id });
        });
        setGroupOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setMachineData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const postApi = () => {
    const postData = {
      data: {
        machineCode,
        description,
        groupOps,
        purchasedDate,
        steam,
        power,
        thermic,
        tankCapacity,
      },
    };
    let validate = requireCheck(postData, machineData, 'machineCode', pickId);
    if (!validate) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'machines' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'machines', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editMachine = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() + `machines/${id}?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit===1);
        setPickId(response.id);
        setDescription(response.attributes.description);
        setMachineCode(response.attributes.machineCode);
        setPurchasedDate(response.attributes.purchasedDate);
        setSteam(response.attributes.steam);
        setPower(response.attributes.power);
        setThermic(response.attributes.thermic);
        setTankCapacity(response.attributes.tankCapacity);
        addPi(response.attributes.groupOps, true);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const showMachine = (id) => {
    showLoader();
    axios
      .get(
        baseUri() + `machines/${id}?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setShowData(response.data.data.attributes.groupOps);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteMachine = (id) => {
    const removeGroup = window.confirm('Would you like to delete this machine?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'machines/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editMachine(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {row.attributes.groupOps.length ? (
            <ActionBtn
              data-tag="allowRowEvents"
              className="btn-rad ml-10"
              onClick={() => {
                handleModalOpen(row.id);
              }}
            >
              {' '}
              <EvaIcon name="eye" />{' '}
            </ActionBtn>
          ) : (
            <></>
          )}
          {pageAccess.delete===1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteMachine(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Machine',
      selector: (row) => `${row.attributes.machineCode} - ${row.attributes.description}`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'PurchaseDate',
      selector: (row) => `${row.attributes.purchasedDate}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'Steam',
      selector: (row) => numFormatter(row.attributes.steam),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Power',
      selector: (row) => numFormatter(row.attributes.power),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Thermic',
      selector: (row) => numFormatter(row.attributes.thermic),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Capacity',
      selector: (row) => numFormatter(row.attributes.tankCapacity),
      sortable: true,
      maxWidth: '5%',
    },
  ];

  const filterColumns = [
    'machineCode',
    'description',
    'group.data.attributes.groupCode',
    'group.data.attributes.description',
    'purchasedDate',
    'steam',
    'power',
    'thermic',
    'tankCapacity',
  ];
  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = machineData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    showMachine(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Group Info</h5>
          {showData &&
            showData.map((row, i) => (
              <div className="div-part1 operation-list" key={randomKey()}>
                <div className="div-part1 operation-part">
                  <p>
                    Group:{' '}
                    <span>{row.group.data.attributes.groupCode + ' - ' + row.group.data.attributes.description}</span>
                  </p>
                  <table className="stripped bordered hover pioperations-table">
                    <thead>
                      <tr>
                        <th width="150px">Operations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.operations &&
                        row.operations.data.map((row2, j) => (
                          <tr key={randomKey()}>
                            <td>{row2.attributes.operationCode + ' - ' + row2.attributes.description}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : null;

  const PiInputs = (len, inputs = false) => {
    let rowDiv = document.createElement('div');
    rowDiv.setAttribute('class', 'row pi-input-container parentGroup piset' + len);
    rowDiv.setAttribute('data-set', len);

    let columnDiv = document.createElement('div');
    columnDiv.setAttribute('class', 'div-part1 mb20');

    let innerColumnDiv = document.createElement('div');
    innerColumnDiv.setAttribute('class', 'div-pio-part pos-relative');
    let label = document.createElement('label');
    label.setAttribute('class', 'div-full select-label');
    label.innerHTML = 'Groups';
    innerColumnDiv.appendChild(label);

    let select = document.createElement('select');
    select.setAttribute('class', 'select-box div-full machineGroups require');
    select.setAttribute('name', 'machineGroups');
    select.setAttribute('id', 'machineGroups_' + len);

    let opt = document.createElement('option');
    opt.setAttribute('value', '');
    opt.innerHTML = 'Select Groups';
    select.appendChild(opt);

    groupOptions.forEach((element) => {
      let opt = document.createElement('option');
      opt.setAttribute('value', element.value);
      if (inputs) {
        if (inputs.group.data.id == element.value) {
          opt.setAttribute('selected', true);
        }
      }
      opt.innerHTML = element.label;
      select.appendChild(opt);
    });
    innerColumnDiv.appendChild(select);

    let innerColumnDiv2 = document.createElement('div');
    innerColumnDiv2.setAttribute('class', 'div-pio-part div-part1 operation-check-container');

    let label2 = document.createElement('label');
    label2.setAttribute('class', 'div-full');
    innerColumnDiv2.appendChild(label2);

    let machineDropDown = document.createElement('div');
    machineDropDown.setAttribute('id', 'machineOperations_' + len);
    innerColumnDiv2.appendChild(machineDropDown);

    let innerColumnDiv4 = document.createElement('div');
    innerColumnDiv4.setAttribute('class', 'div-part10 groupDelContainer');
    let label4 = document.createElement('label');
    label4.setAttribute('class', 'div-full');
    label4.innerHTML = '';
    let delButton = document.createElement('label');
    delButton.setAttribute('class', 'delBtn piOperationDelete div-full');
    let span = document.createElement('span');
    span.innerHTML = '-';

    innerColumnDiv4.appendChild(label4);
    delButton.appendChild(span);
    innerColumnDiv4.appendChild(delButton);

    columnDiv.appendChild(innerColumnDiv);
    columnDiv.appendChild(innerColumnDiv2);
    columnDiv.appendChild(innerColumnDiv4);

    rowDiv.appendChild(columnDiv);
    let mainEl = document.getElementsByClassName('pi-operation-container')[0];
    mainEl.appendChild(rowDiv);

    document.querySelectorAll('.piOperationDelete').forEach(function (el) {
      el.addEventListener('click', function (e) {
        removePi(e);
      });
    });

    if (inputs) {
      let optionList = computeProcessOptions(inputs.group.data.id);
      addMachineOps(optionList, len, inputs.operations);
    }

    document.querySelectorAll('.machineGroups').forEach(function (el) {
      el.addEventListener('change', function (e) {
        showLoader();
        setGroup(e.target.value);
        setdefaultGroup({ label: e.target.label, value: e.target.value });
        let currentID = e.target.id.split('_')[1];
        let optionList = computeProcessOptions(e.target.value);
        addMachineOps(optionList, currentID);
        hideLoader();
      });
    });
  };

  const addMachineOps = (optionList, currentID, inputs = []) => {
    var str = '';
    optionList.forEach((element, index) => {
      let checked = '';
      if (inputs.data) {
        const found = inputs.data.some((el) => el.id == element.value);
        if (found) {
          checked = ' checked="true" ';
        }
      }
      str += '<div class="div-part4">';
      str +=
        '<input type="checkbox" ' +
        checked +
        ' id="machineOperations_' +
        currentID +
        '_' +
        index +
        '" class="machineOperationsList check-input machineOperations_' +
        currentID +
        '" name="machineOperationsList" value="' +
        element.value +
        '">';
      str +=
        '<label class="inline-block  check-label" for="machineOperations_' +
        currentID +
        '_' +
        index +
        '">' +
        element.label +
        '</label>';
      str += '</div>';
    });
    document.getElementById('machineOperations_' + currentID).innerHTML = str;
  };

  const checkpiO = (status) => {
    let title = document.getElementsByClassName('pio-title')[0];
    if (status) {
      title.classList.add('block');
    } else {
      if (document.getElementsByClassName('pi-operation-container')[0].innerHTML) {
        title.classList.add('block');
      } else {
        title.classList.remove('block');
      }
    }
  };

  const addPi = (inputs, edit = false) => {
    if (edit) {
      inputs.forEach((piOps, index) => {
        PiInputs(index, piOps);
      });
      setPiInc(inputs.length);
    } else {
      PiInputs(piInc);
      setPiInc(piInc + 1);
    }
    checkpiO(true);
    doSelect2();
  };

  const removePi = (e) => {
    e.target.closest('.pi-input-container').remove();
    checkpiO();
  };

  const prepareInputs = () => {
    var operationsAr = [];
    document.querySelectorAll('.pi-input-container').forEach(function (piops, iteration) {
      let address = piops.getAttribute('data-set');
      let selectedOperation = piops.querySelectorAll('[name="machineGroups"')[0].value;
      if (selectedOperation) {
        let piConsumables = [];
        let piParent = document.getElementsByClassName('piset' + address)[0];

        var checkboxes = piParent.querySelectorAll('input[type=checkbox]:checked');
        for (var i = 0; i < checkboxes.length; i++) {
          piConsumables[i] = checkboxes[i].value;
        }

        operationsAr[iteration] = {
          group: selectedOperation,
          operations: piConsumables,
        };
      }
    });
    setGroupOps(operationsAr);
    setTimeout(triggerPost, 500);
  };

  const triggerPost = () => {
    document.getElementById('post-data').click();
  };

  return (
    <>
      {popup}
      <SEO title="Machine Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Machine Master</header>

            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>
                    Machine Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="machineCode"
                      placeholder="Machine Code *"
                      onChange={(e) => handleChange(e)}
                      value={machineCode}
                      className="machineCode require"
                    />
                  </Input>
                  <label>
                    Description <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <textarea
                      rows={4}
                      name="description"
                      placeholder="Description *"
                      onChange={(e) => handleChange(e)}
                      value={description}
                      className="description require"
                    />
                  </Input>
                  <label>
                    Machine Purchase Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="purchasedDate"
                      placeholder="Machine Purchase Date *"
                      onChange={(e) => handleChange(e)}
                      value={purchasedDate}
                      className="purchasedDate require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Steam Consumption </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="steam"
                      placeholder="Steam Consumption"
                      maxLength="5"
                      onChange={(e) => handleChange(e)}
                      value={steam}
                      className="steam"
                    />
                  </Input>
                  <label>Power Consumption </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="power"
                      placeholder="Power Consumption"
                      onChange={(e) => handleChange(e)}
                      value={power}
                      maxLength="6"
                      className="power"
                    />
                  </Input>
                  <label>Thermic Heat </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="thermic"
                      placeholder="Thermic Heat"
                      onChange={(e) => handleChange(e)}
                      value={thermic}
                      maxLength="8"
                      className="thermic"
                    />
                  </Input>
                  <label>Water Capacity</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="tankCapacity"
                      placeholder="Water Capacity"
                      onChange={(e) => handleChange(e)}
                      value={tankCapacity}
                      maxLength="6"
                      className="tankCapacity"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12 }} className="pio-title">
                  Add Groups
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="pi-operation-container"></Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button className="addPibtn" onClick={addPi}>
                    Add Groups <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  <Button status="Success" type="button" className="btnrad" disabled={!isSave} shape="" halfWidth onClick={prepareInputs}>
                    Save
                  </Button>
                  <Button
                    status="Success"
                    type="button"
                    id="post-data"
                    className="btnrad hide"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  ></Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Machine Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
